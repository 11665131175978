<script setup>
const props = defineProps(["modelValue"])
const emit = defineEmits(["update:modelValue"])
const route = useRoute()
const constants = useConst()
const categories = constants.categories.reduce((acc, cur) => ({ ...acc, [cur.key]: cur }), {})
const filter = ref(useClone(props.modelValue))

setDefaults()

function setDefaults() {
  emit("update:modelValue", {
    q: "",
    location: "",
    type: route.query.type || "",
    min_price: 0,
    max_price: 0,
    ...props.modelValue,
  })
}

watch(
  () => props.modelValue,
  (v) => {
    if (JSON.stringify(v) != JSON.stringify(filter.value)) {
      filter.value = useClone(v)
    }
  },
)

function emitChange() {
  emit("update:modelValue", useClone(filter.value))
}

defineExpose({
  setDefaults,
})
</script>
<template>
  <v-row>
    <AdvertFilterColumn>
      <Label> Байршил </Label>
      <v-text-field
        v-model="filter.location"
        prepend-inner-icon="icon-map-pin"
        rounded
        variant="outlined"
        color="primary"
        base-color="#8A8A8A"
        placeholder="Байршил"
        density="compact"
        clearable
        @click:clear="props.modelValue.location = ''"
        hide-details
        @change="emitChange"
      />
    </AdvertFilterColumn>
    <AdvertFilterColumn>
      <Label> Ажил </Label>
      <v-text-field
        v-model="filter.q"
        rounded
        variant="outlined"
        color="primary"
        base-color="#8A8A8A"
        placeholder="Ажил"
        density="compact"
        clearable
        @click:clear="props.modelValue.q = ''"
        hide-details
        @change="emitChange"
      />
    </AdvertFilterColumn>
    <AdvertFilterColumn>
      <Label> Төрөл </Label>
      <v-select
        v-model="props.modelValue.type"
        rounded
        variant="outlined"
        color="primary"
        base-color="#8A8A8A"
        density="compact"
        :items="[{ title: 'Бүх зар', value: '' }, ...categories[route.query.cat].types]"
        clearable
        @click:clear="props.modelValue.type = ''"
        hide-details
      />
    </AdvertFilterColumn>
    <AdvertFilterColumn>
      <Label> Цалин доод хязгаар </Label>
      <MoneyInput
        v-model.number.lazy="filter.min_price"
        rounded
        variant="outlined"
        color="primary"
        base-color="#8A8A8A"
        placeholder="Доод үнэ"
        density="compact"
        clearable
        hide-details
        @click:clear="props.modelValue.min_price = 0"
        @change="emitChange"
      />
    </AdvertFilterColumn>
    <AdvertFilterColumn>
      <Label> Цалин дээд хязгаар </Label>
      <MoneyInput
        v-model.number.lazy="filter.max_price"
        rounded
        variant="outlined"
        color="primary"
        base-color="#8A8A8A"
        placeholder="Дээд үнэ"
        density="compact"
        clearable
        hide-details
        @click:clear="props.modelValue.max_price = 0"
        @change="emitChange"
      />
    </AdvertFilterColumn>
  </v-row>
</template>
