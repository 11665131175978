<script setup>
const props = defineProps(["modelValue"])
const emit = defineEmits(["update:modelValue"])
const route = useRoute()
const constants = useConst()
const categories = constants.categories.reduce((acc, cur) => ({ ...acc, [cur.key]: cur }), {})
const filter = ref(useClone(props.modelValue))

setDefaults()

function setDefaults() {
  emit("update:modelValue", {
    location: "",
    min_price: 0,
    max_price: 0,
    bond_or_bedroom: 0,
    rent_or_bathroom: 0,
    living_room_or_parking: null,
    ...props.modelValue,
  })
}

watch(
  () => props.modelValue,
  (v) => {
    if (JSON.stringify(v) != JSON.stringify(filter.value)) {
      filter.value = useClone(v)
    }
  },
)

function emitChange() {
  emit("update:modelValue", useClone(filter.value))
}

defineExpose({
  setDefaults,
})
</script>
<template>
  <v-row>
    <AdvertFilterColumn>
      <Label> Байршил </Label>
      <v-text-field
        v-model="filter.location"
        prepend-inner-icon="icon-map-pin"
        rounded
        variant="outlined"
        color="primary"
        base-color="#8A8A8A"
        placeholder="Байршил"
        density="compact"
        clearable
        @click:clear="props.modelValue.location = ''"
        hide-details
        @change="emitChange"
      />
    </AdvertFilterColumn>
    <AdvertFilterColumn>
      <Label> Доод үнэ / 7 хоног </Label>
      <MoneyInput
        v-model.number.lazy="filter.min_price"
        rounded
        variant="outlined"
        color="primary"
        base-color="#8A8A8A"
        placeholder="Доод үнэ"
        density="compact"
        clearable
        hide-details
        @click:clear="props.modelValue.min_price = 0"
        @change="emitChange"
      />
    </AdvertFilterColumn>
    <AdvertFilterColumn>
      <Label> Дээд үнэ / 7 хоног </Label>
      <MoneyInput
        v-model.number.lazy="filter.max_price"
        rounded
        variant="outlined"
        color="primary"
        base-color="#8A8A8A"
        placeholder="Дээд үнэ"
        density="compact"
        clearable
        hide-details
        @click:clear="props.modelValue.max_price = 0"
        @change="emitChange"
      />
    </AdvertFilterColumn>
    <AdvertFilterColumn>
      <Label> Bedroom </Label>
      <v-select
        v-model="props.modelValue.bond_or_bedroom"
        color="primary"
        base-color="#8A8A8A"
        :items="[{ title: 'Бүгд', value: 0 }, ...constants.bedAndBath]"
        variant="outlined"
        density="compact"
        hide-details
        clearable
        rounded
        @click:clear="props.modelValue.bond_or_bedroom = 0"
      ></v-select>
    </AdvertFilterColumn>
    <AdvertFilterColumn>
      <Label> Bathroom </Label>
      <v-select
        v-model="props.modelValue.rent_or_bathroom"
        color="primary"
        base-color="#8A8A8A"
        :items="[{ title: 'Бүгд', value: 0 }, ...constants.bedAndBath]"
        variant="outlined"
        density="compact"
        hide-details
        clearable
        rounded
        @click:clear="props.modelValue.rent_or_bathroom = 0"
      ></v-select>
    </AdvertFilterColumn>
    <AdvertFilterColumn>
      <Label> Parking </Label>
      <v-select
        v-model.boolean="props.modelValue.living_room_or_parking"
        color="primary"
        base-color="#8A8A8A"
        :items="[{ title: 'Бүгд', value: null }, ...constants.parkings]"
        variant="outlined"
        density="compact"
        hide-details
        clearable
        rounded
      ></v-select>
    </AdvertFilterColumn>
  </v-row>
</template>
