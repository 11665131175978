<script setup>
const route = useRoute()
const router = useRouter()
const constants = useConst()
const categories = constants.categories
const banners = useBanners()
const city = useCity()
const categoriesMap = categories.reduce((acc, cur) => ({ ...acc, [cur.key]: cur }), {})
const filterDialog = ref(false)
const mobileFilter = ref()
const searchKeyword = ref(route.query.q || "")
const searchBar = ref(false)

const bannerIdx = ref(0)
const breadCrumbItems = computed(() => {
  let items = [{ title: "Нүүр", disabled: false, to: "/" }]

  if (route.query.cat && categoriesMap[route.query.cat]) {
    let cat = categoriesMap[route.query.cat]
    items.push({
      title: cat.name,
      disabled: false,
      to: { path: "/", query: { cat: route.query.cat, subcat: "", type: "" } },
    })

    if (route.query.subcat && cat.subcat?.length) {
      for (let subcat of cat.subcat) {
        if (subcat.key == route.query.subcat) {
          items.push({
            title: subcat.name,
            disabled: false,
            to: { path: "/", query: { cat: route.query.cat, subcat: route.query.subcat, type: "" } },
          })
          break
        }
      }
    }

    if (route.query.type && cat.types?.length) {
      for (let t of cat.types) {
        if (t == route.query.type) {
          items.push({
            title: t,
            disabled: false,
            to: { path: "/", query: { cat: route.query.cat, subcat: "", type: route.query.type } },
          })
          break
        }
      }
    }
  }

  return items
})

async function onChooseCat(cat, val = null, isChoosing = "subcat") {
  let t = { path: "/", query: {} }

  if (route.query.cat != cat) {
    t.query.cat = cat
  }

  if (val == "Бүх зар") {
    val = null
  }

  if (val) {
    switch (isChoosing) {
      case "subcat":
        if (route.query.subcat != val) {
          t.query.subcat = val
        }
        break
      case "type":
        if (route.query.type != val) {
          t.query.type = val
        }
        break
    }
  }

  if (Object.entries(t.query).length > 0) {
    router.push(t)
  }
}

function onMobileFilterClick() {
  mobileFilter.value.filter()
  filterDialog.value = false
}

function onSearch() {
  router.push({
    path: "/",
    query: {
      ...route.query,
      q: searchKeyword.value,
    },
  })
}
</script>
<template>
  <v-main>
    <v-container>
      <!-- Home page header -->
      <template v-if="!route.query.cat && !route.query.q">
        <v-row v-if="banners['main']?.length">
          <v-col cols="12">
            <v-carousel hide-delimiters height="auto" v-model="bannerIdx" cycle>
              <template v-slot:prev="{ props }">
                <v-btn
                  v-if="!$vuetify.display.smAndDown"
                  size="x-small"
                  variant="flat"
                  color="grey"
                  icon
                  @click="props.onClick"
                >
                  <v-icon size="x-large" icon="icon-chevron-left" />
                </v-btn>
              </template>
              <template v-slot:next="{ props }">
                <v-btn
                  v-if="!$vuetify.display.smAndDown"
                  size="x-small"
                  variant="flat"
                  color="grey"
                  icon
                  @click="props.onClick"
                >
                  <v-icon size="x-large" icon="icon-chevron-right" />
                </v-btn>
              </template>

              <NuxtLink v-for="b in banners['main']" :href="b.link" target="_blank" class="banner-link">
                <v-carousel-item
                  :src="$vuetify.display.smAndDown ? b.phone_image_url : b.com_image_url"
                  :lazy-src="$vuetify.display.smAndDown ? b.phone_image_url : b.com_thumbnail_url"
                />
              </NuxtLink>
            </v-carousel>
            <div v-if="$vuetify.display.smAndDown" class="text-center">
              <v-btn
                v-for="(b, idx) in banners['main']"
                variant="text"
                icon
                density="compact"
                height="32"
                width="32"
                :color="bannerIdx == idx ? 'primary' : 'grey'"
                @click="bannerIdx = idx"
              >
                <v-icon icon="icon-dot" size="x-large" />
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="!route.query.user_id" class="flex-nowrap overflow-auto hide-scrollbar">
          <v-col :class="$vuetify.display.smAndDown ? 'text-no-wrap' : 'text-center'">
            <v-menu v-for="category in categories" :key="category.key">
              <template v-slot:activator="{ props }">
                <v-card
                  v-bind="props"
                  class="d-inline-block text-center px-2 py-4 ma-2"
                  flat
                  rounded="lg"
                  color="#F8F8F8"
                  min-width="130"
                  @dblclick="onChooseCat(category.key)"
                  @click="category.subcat?.length || category.types?.length || onChooseCat(category.key)"
                >
                  <img :src="category.icon" height="40" />
                  <div class="text-subtitle-2 font-weight-bold">
                    {{ category.name }}
                  </div>
                </v-card>
              </template>

              <v-list v-if="category.subcat?.length || category.types?.length" nav color="primary">
                <v-list-item
                  @click="
                    onChooseCat(
                      category.key,
                      subcat.key ? subcat.key : subcat,
                      category.subcat?.length ? 'subcat' : 'type',
                    )
                  "
                  v-for="(subcat, index) in category.subcat?.length
                    ? [{ name: 'Бүх зар', key: 'Бүх зар' }, ...category.subcat]
                    : ['Бүх зар', ...category.types]"
                  :key="index"
                >
                  <v-list-item-title>{{ subcat.name ? subcat.name : subcat }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="d-flex justify-space-between align-center">
              <template v-if="!route.query.user_id">
                <h1>Шинэ зарууд</h1>
              </template>
              <template v-else>
                <h1>Зарын эзний бусад зарууд</h1>
                <v-btn to="/" variant="text" exact>Бүх зар үзэх</v-btn>
              </template>
            </div>
          </v-col>
        </v-row>
      </template>
      <!-- Home page header end -->

      <!-- Cat page header -->
      <template v-else>
        <template v-if="$vuetify.display.mdAndUp">
          <v-row>
            <v-col>
              <v-breadcrumbs :items="breadCrumbItems" active-color="#8A8A8A" class="text-subtitle-2 px-0">
                <template v-slot:divider>
                  <v-icon icon="icon-chevron-right" color="#8A8A8A" size="small"></v-icon>
                </template>
              </v-breadcrumbs>
            </v-col>
          </v-row>
          <v-row class="mb-4">
            <v-col>
              <h1>
                {{ route.query.q ? "Хайлтын илэрц" : breadCrumbItems[breadCrumbItems.length - 1].title }}
              </h1>
            </v-col>
          </v-row>
          <AdvertFilter immediate />
        </template>
        <template v-else>
          <v-app-bar>
            <v-container class="d-flex py-0 align-center font-weight-bold">
              <template v-if="!searchBar">
                <v-btn icon="icon-arrow-left" size="small" to="/" variant="text" exact />
                {{ breadCrumbItems.length == 1 ? "Хайлтын илэрц" : breadCrumbItems[breadCrumbItems.length - 1].title }}
                <v-spacer />
                <v-btn
                  height="48"
                  width="48"
                  size="small"
                  color="primary"
                  border
                  variant="text"
                  class="mr-1"
                  @click="searchBar = true"
                >
                  <v-icon size="x-large" icon="icon-search" />
                </v-btn>

                <v-btn height="48" width="48" size="small" color="primary" variant="flat" @click="filterDialog = true">
                  <v-icon size="x-large" icon="icon-sliders-horizontal" />
                </v-btn>
              </template>
              <template v-else>
                <v-btn icon="icon-arrow-left" size="small" variant="text" exact @click="searchBar = false" />
                <v-form @submit.prevent="onSearch" class="flex-grow-1">
                  <v-text-field
                    v-model="searchKeyword"
                    prepend-inner-icon="icon-search"
                    placeholder="Та юу хайж байна вэ?"
                    variant="outlined"
                    hide-details
                    density="comfortable"
                    clearable
                    @click:clear="onSearch"
                    name="search_q"
                  >
                    <template #append-inner>
                      <v-menu activator="parent">
                        <template v-slot:activator="{ props }">
                          <v-icon v-bind="props" icon="icon-map-pin" color="primary" />
                        </template>
                        <v-list color="primary">
                          <v-list-item v-for="c in constants.cities" @click="city = c" :active="city == c">
                            <v-list-item-title>{{ c }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-form>
              </template>
            </v-container>
          </v-app-bar>
          <v-dialog v-model="filterDialog" transition="dialog-bottom-transition" fullscreen>
            <v-card>
              <v-toolbar color="white" elevation="4">
                <v-btn icon="icon-x" size="small" color="grey" @click="filterDialog = false" variant="text"></v-btn>

                <div class="font-weight-bold">Филтер хийх</div>

                <v-spacer></v-spacer>

                <v-btn color="primary" variant="tonal" density="comfortable" @click="mobileFilter.clear()">
                  Цэвэрлэх
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <AdvertFilter ref="mobileFilter" :immediate="false" />
              </v-card-text>
              <v-card-actions>
                <v-btn variant="flat" color="secondary" size="large" block @click="onMobileFilterClick">
                  Зар үзэх
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </template>
      <!-- Cat page header end -->
      <div class="mt-8">
        <AdvertList />
      </div>
    </v-container>

    <Footer />
  </v-main>
</template>
<style scoped lang="scss">
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
</style>
