<script setup>
const props = defineProps(["modelValue"])
const emit = defineEmits(["update:modelValue"])
const constants = useConst()
const minPrice = ref(0)
const maxPrice = ref(0)
const filter = ref(useClone(props.modelValue))

setDefaults()

function setDefaults() {
  emit("update:modelValue", {
    location: "",
    type: "",
    min_price: 0,
    max_price: 0,
    min_price2: 0,
    max_price2: 0,
    living_room_or_parking: null,
    person_number: 1,
    ...props.modelValue,
  })
}

watch(
  () => props.modelValue,
  (v) => {
    if (JSON.stringify(v) != JSON.stringify(filter.value)) {
      filter.value = useClone(v)
      if (filter.value.person_number == "2") {
        minPrice.value = filter.value.min_price2
        maxPrice.value = filter.value.max_price2
      } else {
        minPrice.value = filter.value.min_price
        maxPrice.value = filter.value.max_price
      }
    }
  },
)

watch(
  () => filter.value.person_number,
  (v) => {
    if (v == "1") {
      filter.value.min_price = minPrice.value
      filter.value.max_price = maxPrice.value
      filter.value.min_price2 = 0
      filter.value.max_price2 = 0
    } else if (v == "2") {
      filter.value.min_price2 = minPrice.value
      filter.value.max_price2 = maxPrice.value
      filter.value.min_price = 0
      filter.value.max_price = 0
    }
    emitChange()
  },
)

function emitChange() {
  if (filter.value.person_number == "1") {
    filter.value.min_price = minPrice.value
    filter.value.max_price = maxPrice.value
    filter.value.min_price2 = 0
    filter.value.max_price2 = 0
  } else if (filter.value.person_number == "2") {
    filter.value.min_price2 = minPrice.value
    filter.value.max_price2 = maxPrice.value
    filter.value.min_price = 0
    filter.value.max_price = 0
  }
  emit("update:modelValue", useClone(filter.value))
}

function onMaxClear() {
  maxPrice.value = 0
  emitChange()
}

function onMinClear() {
  minPrice.value = 0
  emitChange()
}

defineExpose({
  setDefaults,
})
</script>
<template>
  <v-row>
    <AdvertFilterColumn>
      <Label> Байршил </Label>
      <v-text-field
        v-model="filter.location"
        prepend-inner-icon="icon-map-pin"
        rounded
        variant="outlined"
        color="primary"
        base-color="#8A8A8A"
        placeholder="Байршил"
        density="compact"
        clearable
        @click:clear="props.modelValue.location = ''"
        hide-details
        @change="emitChange"
      />
    </AdvertFilterColumn>
    <AdvertFilterColumn>
      <Label> Төрөл </Label>
      <v-select
        v-model="props.modelValue.type"
        color="primary"
        base-color="#8A8A8A"
        :items="[{ title: 'Бүгд', value: '' }, ...constants.roomTypes]"
        variant="outlined"
        density="compact"
        hide-details
        clearable
        rounded
        @click:clear="props.modelValue.type = ''"
      ></v-select>
    </AdvertFilterColumn>
    <AdvertFilterColumn>
      <Label> Доод үнэ / 7 хоног </Label>
      <MoneyInput
        v-model.number="minPrice"
        rounded
        variant="outlined"
        color="primary"
        base-color="#8A8A8A"
        placeholder="Доод үнэ"
        density="compact"
        clearable
        hide-details
        @click:clear="onMinClear"
        @change="emitChange"
      />
    </AdvertFilterColumn>
    <AdvertFilterColumn>
      <Label> Дээд үнэ / 7 хоног </Label>
      <MoneyInput
        v-model.number="maxPrice"
        rounded
        variant="outlined"
        color="primary"
        base-color="#8A8A8A"
        placeholder="Дээд үнэ"
        density="compact"
        clearable
        hide-details
        @click:clear="onMaxClear"
        @change="emitChange"
      />
    </AdvertFilterColumn>
    <AdvertFilterColumn>
      <Label> Хүний тоо </Label>
      <v-select
        v-model="filter.person_number"
        color="primary"
        base-color="#8A8A8A"
        :items="[1, 2]"
        variant="outlined"
        density="compact"
        hide-details
        rounded
      ></v-select>
    </AdvertFilterColumn>
    <AdvertFilterColumn>
      <Label> Living room </Label>
      <v-select
        v-model="props.modelValue.living_room_or_parking"
        color="primary"
        base-color="#8A8A8A"
        :items="[{ title: 'Бүгд', value: null }, ...constants.living_room]"
        variant="outlined"
        density="compact"
        hide-details
        clearable
        rounded
        @click:clear="props.modelValue.living_room_or_parking = null"
      ></v-select>
    </AdvertFilterColumn>
  </v-row>
</template>
