<script setup>
const props = defineProps({
  immediate: {
    type: Boolean,
    default: false,
  },
})
const route = useRoute()
const router = useRouter()
const filter = ref(route.query)
const activeFilter = ref()

// Watch for changes in query parameters
watch(
  () => route.query,
  (value) => {
    if (JSON.stringify(value) != JSON.stringify(filter.value)) {
      filter.value = {
        ...filter.value,
        ...value,
      }
    }
  },
  { deep: true },
)

watch(
  filter,
  (value) => {
    console.log(value)
    if (JSON.stringify(value) != JSON.stringify(route.query) && props.immediate) {
      doFilter()
    }
  },
  { deep: true },
)

function doFilter() {
  let query = {
    ...route.query,
    ...filter.value,
  }
  router.push({ path: "/", query })
}

function clear() {
  filter.value = {
    cat: route.query.cat || null,
    subcat: route.query.subcat || null,
    type: route.query.type || null,
  }
  if (activeFilter.value) {
    nextTick(() => {
      activeFilter.value.setDefaults()
    })
  }
}

defineExpose({
  filter: doFilter,
  clear,
})
</script>
<template>
  <template
    v-if="['electronics', 'household', 'car', 'computer', 'tool'].includes(route.query.cat)"
  >
    <AdvertFilterCommon ref="activeFilter" v-model="filter" show-types />
  </template>

  <template v-else-if="route.query.cat == 'immovable'">
    <AdvertFilterHouse ref="activeFilter" v-if="route.query.subcat == 'house'" v-model="filter" />
    <AdvertFilterRoom
      ref="activeFilter"
      v-else-if="route.query.subcat == 'room'"
      v-model="filter"
    />
    <AdvertFilterCommon ref="activeFilter" v-else v-model="filter" :hide-durability="true" />
  </template>

  <template v-else-if="route.query.cat == 'free'">
    <AdvertFilterCommon ref="activeFilter" v-model="filter" :hide-prices="true" />
  </template>

  <template v-else-if="route.query.cat == 'other'">
    <AdvertFilterCommon ref="activeFilter" v-model="filter" />
  </template>

  <template v-else-if="route.query.cat == 'job'">
    <AdvertFilterJob ref="activeFilter" v-model="filter" />
  </template>

  <template v-else="route.query.cat == 'other'">
    <AdvertFilterCommon ref="activeFilter" v-model="filter" :hide-durability="true" />
  </template>
</template>
